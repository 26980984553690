import React from 'react';
import './AboutUsCards.css';

function Personell() {

      return (

    <div className='personell-heading' style={{textAlign:'center', paddingTop:'80px', fontFamily:'Montserrat, sans-serif',fontWeight:'500', fontSize:'3rem'}} >
        <h1>Our Team</h1>
    </div>
      )

}
export default Personell;