import OurHistory from "./History";



function HistoryPage(){
    return(
    <div>
        <OurHistory />
    </div>
    );
}

export default HistoryPage;